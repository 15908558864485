import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    window.location.replace("https://quantum-quench-hub.square.site");
  }, []);

  return <div className="App"></div>;
}

export default App;
